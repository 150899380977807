import { Stripe, loadStripe } from "@stripe/stripe-js";

let stripePromise: Stripe | null;

const initializeStripe = async () => {
  const publishKey: string = process.env.GATSBY_STRIPE_PUBLISH_KEY ? process.env.GATSBY_STRIPE_PUBLISH_KEY : '';
  if (!stripePromise) {
    stripePromise = await loadStripe(
      publishKey
    );
  }
  return stripePromise;
};
export default initializeStripe;