import { initializeApp } from "firebase/app";

const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: "remoteinclusive.firebaseapp.com",
  projectId: "remoteinclusive",
  storageBucket: "remoteinclusive.appspot.com",
  messagingSenderId: "533601831306",
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: "G-0QJRG11H7C"
};
const firebaseApp = initializeApp(config);

export default firebaseApp;