import { useContext, useEffect, useState } from 'react';
import { StateContext, DispatchContext } from '../utils/providers';
import { JobState } from '../reducers/jobReducer';
import { ShoppingCartIcon, ShoppingCartSolidIcon } from '@fluentui/react-icons-mdl2';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LinearProgess from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
import { getAuth } from 'firebase/auth';
import FirebaseApp from '../utils/firebaseApp';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createCheckoutSession } from '../utils/createCheckout';

const Checkout = () => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const jobState:JobState = state.job;
  const auth = getAuth(FirebaseApp);
  const [open, setOpen] = useState(false);
  const [loadingCheckout, setLoadingCheckout] = useState(false);
  let jobsInCartCount = jobState.jobsInCart?.length || 0;
  const jobsInCart = jobState.jobsInCart;

  const startCheckout = () => {
    if (jobsInCartCount === 0 || !jobsInCart) 
      return;

    setLoadingCheckout(true);
    const promotedCount = jobsInCart.reduce((prevVal, job) => {
      return job.promoted ? prevVal + 1 : prevVal;
    }, 0);
  
    const jobIds = jobsInCart.reduce((prevVal, job, index) => {
      if (index > 0) {
        return prevVal + ',' + job.jobId;
      }
      return prevVal + job.jobId;
    }, "");
  
    let lineItems = [
      {
        price: "remoteinclusive_listing",
        quantity: jobsInCart.length      
      },
    ];
  
    if (promotedCount) {
      lineItems.push({
        price: 'remoteinclusive_promoted',
        quantity: promotedCount
      })
    }
    const uid = auth.currentUser ? auth.currentUser.uid : '';
    createCheckoutSession(uid, lineItems, jobIds);
  }

  const handleCartClose = () => {
    setOpen(false);
  }

  return (
    <>
      <IconButton aria-label="cart" onClick={() => setOpen(true)}>
        <Badge badgeContent={jobsInCartCount} color="secondary">
          {jobsInCartCount === 0 && <ShoppingCartIcon />}
          {jobsInCartCount > 0 && <ShoppingCartSolidIcon />}
        </Badge>
      </IconButton>
      <CartDialogue
        open={open}
        onClose={handleCartClose}
        onCheckout={startCheckout}
        loading={loadingCheckout} />
    </>
  );
};

type CartDialogueProps = {
  open: boolean,
  onClose: any,
  onCheckout: any,
  loading: boolean
};

const CartDialogue = ({open, onClose, onCheckout, loading}: CartDialogueProps) => {  
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const state = useContext(StateContext);
  const jobState:JobState = state.job;
  let disableCheckout = false;
  if (!jobState.jobsInCart || jobState.jobsInCart.length <= 0) {
    disableCheckout = true;
  }
  return (
    <Dialog onClose={onClose} open={open} fullScreen={fullScreen} >
      <DialogTitle>Job Posts in Cart</DialogTitle>
        <List sx={{ pt: 0 }}>
          {jobState.jobsInCart?.map((job) => {
            let price = 150;
            if (job.promoted) {
              price += 50;
            }
            const secondaryText = "Price: $" + price;
            return (
              <ListItem key={job.jobId}>
                <ListItemText primary={job.jobTitle} secondary={secondaryText} />
              </ListItem>
            )
          })}
        </List>        
        {!loading && (
          <DialogActions>
            <Button autoFocus onClick={onClose}>
              Cancel
            </Button>
            <Button disabled={disableCheckout} onClick={onCheckout}>Checkout</Button>
          </DialogActions>
        )}
        {loading && (
          <LinearProgess />
        )}
    </Dialog>
  )
}

export default Checkout;