import { useState } from 'react';
import { Link } from 'gatsby';
import Logo from './remoteinclusive_logo.svg';
import Button, { ButtonType } from './button';
import firebaseApp from '../utils/firebaseApp';
import { getAuth, signOut } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import Login from './login';
import Checkout from './checkout';

import './nav.scss';

const Nav = () => {
  const auth = getAuth(firebaseApp);
  const [user] = useAuthState(auth);
  const [open, setOpen] = useState(false);

  const closeDialog = () => {
    setOpen(false);
  }

  const openDialog = () => {
    setOpen(true);
  }

  return (
    <nav className="navigation">
      <div className="navigation__links">
        <div>
          <Link to="/" className="navigation__logo">
            <img src={Logo} alt="logo" className="navigation__logo-img" />
            RemoteInclusive
          </Link>
        </div>
        <ul>
          {
            user && (
              <li><Link to="/myjobs">My Jobs</Link></li>
            )
          }
          <li><Link to="/search">Job Search</Link></li>
          <li><Link to="/post">Post a Job</Link></li>
        </ul>
      </div>
      { user && (
        <>
          <Checkout />
          <Button onClick={() => {signOut(auth)}} className="navigation__sign-in" buttonType={ButtonType.secondary}>Sign Out</Button>
        </>
      )}
      { !user && (
        <Button onClick={openDialog} className="navigation__sign-in" buttonType={ButtonType.secondary}>Sign In</Button>
      )}
      <Login close={closeDialog} open={open} />
    </nav>
  )
}

export default Nav;
