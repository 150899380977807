import { Card, CardMedia, CardContent } from '@mui/material';
import { graphql, StaticQuery, Link } from 'gatsby';
import { useState } from 'react';
import './footer.scss';

const footer = () => {
  const [cardArticles, setCardArticles] = useState([]);
  return (
    <footer>
      <div className="footer__links">
        {cardArticles.map((article) => {
          return (
            <Link to={article.frontmatter.slug}>
              <Card className="footer__card">
                <CardMedia
                  component="img"
                  height="140"
                  image={article.frontmatter.cardimage.publicURL}
                  alt="interviewing" />
                <CardContent>
                  <h3>{article.frontmatter.title}</h3>
                </CardContent>
              </Card>
            </Link>
          )                
        })}  
      </div>
      <div className="footer__links">
        <div className="footer__links-column">
          <Link to="/post">Post a Job</Link>
          <Link to="/search">Search for a Job</Link>
          <Link to="/press">Press</Link>
          <a href="https://www.linkedin.com/company/remoteinclusive">RemoteInclusive Company</a>
          <a href="mailto:team@remoteinclusive.com">Contact Us</a>
          <a href="mailto:help@remoteinclusive.com">Need Help?</a>
        </div>
        <div className="footer__links-column">
          Articles
          <StaticQuery
            query={graphql`
              query FooterQuery {
                allMarkdownRemark(
                  filter: {frontmatter: {templateKey: {eq: "article"}}}
                  sort: {order: DESC, fields: frontmatter___date}
                ) {
                  nodes {
                    frontmatter {
                      date
                      cardimage {
                        publicURL
                      }
                      slug
                      title
                    }
                  }
                }
              }
            `}
            render={data => {
              const articles = data.allMarkdownRemark.nodes;
              if(!cardArticles || cardArticles.length <= 0)
                setCardArticles(articles.slice(0, 2));
              return (
                <>
                {
                  articles.map((node) => {
                    return (<a href={node.frontmatter.slug}>{node.frontmatter.title}</a>)
                  })
                }
                </>
                )
            }} />
        </div>
      </div>
    </footer>
  )
}

export default footer;
