import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';
import Header from './header';
import Footer from './footer';
import '../components/all.scss';

type LayoutProps = {
  children: React.ReactNode,
  pageTitle: string,
  description: string
}

const Layout = ({pageTitle, description, children}: LayoutProps) => {
  return (
    <main>
      <Helmet>
        <html lang="en" />
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
        <meta property="image" content={`https://remoteinclusive.com${withPrefix('/')}img/remoteinclusive_meta_img.jpg`} />
        <meta name="theme-color" content="#fff" />
        <meta charSet="utf-8" />
        <meta name="twitter:creator" content="@RemoteInclusive" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@RemoteInclusive" />
        <meta property="og:site_name" content="RemoteInclusive" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={`https://remoteinclusive.com${withPrefix('/')}`} />
        <meta property="og:image" content={`https://remoteinclusive.com${withPrefix('/')}img/remoteinclusive_meta_img.jpg`} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`https://remoteinclusive.com${withPrefix('/')}img/apple-touch-icon-180x180.png`}
        />
        <link
          rel="icon"
          type="image/svg"
          href={`https://remoteinclusive.com${withPrefix('/')}img/favicon.svg`}
          sizes="any"
        />

        <link
          rel="mask-icon"
          href={`https://remoteinclusive.com${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />

        <link rel="icon" href={`https://remoteinclusive.com${withPrefix('/')}img/safari-pinned-tab.svg`} sizes="any" type="image/svg+xml" />
      </Helmet>
      <Header />
      {children}
      <Footer />
    </main>
  );
}

export default Layout;
