import firebase from "./firebaseApp";
import getStripe from "./initializeStripe";
import { getFirestore, collection, doc, addDoc, onSnapshot } from "firebase/firestore";

export async function createCheckoutSession(uid: string,
  lineItems: object,
  jobIds: string) {
  const db = getFirestore(firebase);

  const cust = await doc(db, "customers", uid);
  const sessionsColl = await collection(cust, "checkout_sessions");
  const newDoc = await addDoc(sessionsColl, {
    mode: "payment",
    success_url: `${window.location.origin}/myjobs`,
    cancel_url: `${window.location.origin}/myjobs`,
    line_items: lineItems,
    metadata: {
      "jobIds": jobIds
    }
  });

  const snap = onSnapshot(newDoc, async (snap) => {
    const { sessionId } = snap.data();
    if (sessionId) {
      // We have a session, let's redirect to Checkout
      // Init Stripe
      const stripe = await getStripe();
      stripe?.redirectToCheckout({ sessionId });
    }
  });
}