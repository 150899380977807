import Dialog from '@mui/material/Dialog'; 
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { GoogleAuthProvider, EmailAuthProvider } from 'firebase/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebaseApp from '../utils/firebaseApp';
import { getAuth, OAuthProvider } from 'firebase/auth';

type LoginProps = {
  open: boolean,
  close: () => void
};

const Login = ({open, close}: LoginProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const auth = getAuth(firebaseApp);

  // const linkedinProvider = new OAuthProvider('linkedin.com');
  // linkedinProvider.addScope('r_emailaddress');
  // linkedinProvider.addScope('r_liteprofile');
  
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/post',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      {
        provider: GoogleAuthProvider.PROVIDER_ID,
        fullLabel: 'Sign in/create account with google'
      },
      {
        provider: EmailAuthProvider.PROVIDER_ID,
        fullLabel: 'Sign in/create account with email'
      }
    ],
  };
  
  return (
    <Dialog
      onClose={() => close()}
      open={open}
      fullScreen={fullScreen}>
      <DialogTitle>Choose method to login or Sign-Up!</DialogTitle>
      <DialogContent>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
      </DialogContent>
    </Dialog>
  );
}

export default Login;