import './button.scss';

export enum ButtonType {
  primary = 1,
  secondary
}

type ButtonProps = {
  buttonType: ButtonType,
  className?: string,
  children: React.ReactNode,
  onClick: () => void
}

const Button = ({buttonType, className, children, onClick}: ButtonProps) => {
  return (
    <button
      className={`button-${buttonType === ButtonType.primary ? 'primary' : 'secondary'} ${className}`}
      onClick={onClick}>
      {children}
    </button>
  );
}

export default Button;
